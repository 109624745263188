<template>
  <component
    :is="href ? CommonLink : 'button'"
    class="flex items-center justify-center rounded-md transition hover:scale-110"
    :class="[VARIANTS[variant], SIZES[size]]"
    v-bind="href ? { href } : {}"
  >
    <slot />
  </component>
</template>

<script lang="ts" setup>
import CommonLink from '@autobid/ui/components/common/Link.vue'

const VARIANTS = {
  border: 'border border-primary bg-white text-primary',
  primary: 'border border-primary bg-primary text-white',
  orange: 'bg-[#F59C00] text-white',
  blue: 'bg-[#2f8ac9] text-white',
  purple: 'border border-[#8635e5] text-white'
}

const SIZES = {
  big: 'h-11 w-11 text-3xl border-2 md:border md:text-xl',
  small: 'h-8 w-8 text-xl',
  dynamic: 'h-11 w-11 text-3xl border-2 md:border md:text-xl md:h-8 md:w-8'
}

interface Props {
  variant: keyof typeof VARIANTS
  size: keyof typeof SIZES
  href?: string
}

defineProps<Props>()
</script>
